.sliceContainer {
  margin-top: var(--spacing40);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containerColumn,
.containerColumnProcess {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
}


.ctaContainer {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

@media (min-width: 768px) {
  .ctaContainer {
    padding-left: 50%;
  }
}
.cta {
 margin: 0 auto;
}



.itemContainer {
  border-bottom: 1px solid var(--colorGrey);
  padding: var(--spacing32) 0px;
}

.icon {
  width: 65px;
}

.itemTitle {
  font-weight: var(--fontWeightThick);
}

.itemTitle > p {
  margin-bottom: 0px;
}

.itemBodyText > p {
  margin-top: var(--spacing8);
}

@media (min-width: 550px) {
  .itemContainer {
    border-bottom: none;
    padding-left: var(--spacing32);
    padding-right: var(--spacing32);
    border-left: 1px solid var(--colorGrey);
    /* min-height: 430px; */
  }
  
  .processContainer {
    display: flex;
    flex-direction: row;
    margin-top: var(--spacing32);
    border-right: 1px solid var(--colorGrey);
  }

  .processContainer > div:first-child {
    /* border-left: 1px solid var(--colorGrey); */
    /* border-right: 1px solid var(--colorGrey); */
    /* padding-left: var(--spacing24); */
  }



  .processColumn {
    display: flex;
    /* flex-direction: column; */
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .itemContainer {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .sliceContainer {
    flex-direction: row;
    width: 100%;
    margin-top: 0px;
  }
  .processContainer {
    width: 50%;
    margin-top: 0px;
  }

  .containerColumn {
     width: 40%;
     margin-right: 10%;
    }

  .processContainer > div:first-child {
    /* padding-left: var(--spacing24); */
    /* padding-right: var(--spacing24); */
  }
  .processContainer > div:last-child {
    /* border-right: 1px solid var(--colorGrey); */
    /* padding-right: var(--spacing24); */
  }

  .image {
    max-width: 512px;
    margin-right: var(--spacing24);
  }
}
