.container {
  display: block;
  height: 100%;
  width: 100%;
}

.deliveryContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: var(--fontSizeS);
  font-weight: var(--fontWeightThick);
  margin-top: var(--spacing16);
}

.button {
  margin-top: var(--spacing24);
}

.inputContainer {
  margin-top: var(--spacing32);
  text-align: center;
}

.inputContainer input {
  max-width: 100% !important;
}

.bodyText {
  font-size: var(--fontSizeXS);
}

.image {
  align-self: center;
  width: 100%;
  margin-top: 2vw;
}

.image span {
  aspect-ratio: 3 / 4;
}

.image img {
  aspect-ratio: 3 / 4;
  object-fit: cover;
}

@media (--desktop) {
  .title {
    margin-top: 0px;
  }

  .formInfo {
    max-width: 40vw;
  }

  .deliveryContainer {
    width: 50%;
    align-items: flex-start;
    justify-content: center;
    min-height: inherit;
  }

  .inputContainer {
    margin-top: var(--spacing32);
    text-align: start;
  }

  .inputContainer input {
    max-width: initial;
  }

  .deliveryContainerImage {
    justify-content: center;
    align-items: center;
    height: 100%;
  }

     .container {
       width: 50%;
     }
  
     .image {
       width: 70%;
     }


}

@media (--desktopXL) {

}


@media (--desktopXXL) {


 
}