.title {
  font-size: var(--fontSizeS);
  font-weight: var(--fontWeightThick);
  margin-bottom: var(--spacing40);
}

.styleValuesContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
}

.contentContainer {
  height: 100%;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -90px;
  max-height: 800px;
}

.carouselProvider {
  width: 100%;
}

.imageContainer {
  margin: 0px 10% 0px 10%;
  min-width: 200px;
  width: 50vw;
  max-width: 400px;
  align-self: center;
}


.imageContainer img,
.imageContainer span {
  aspect-ratio: 3 / 4;
  object-fit: cover;
}
.overlayTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlayText {
  position: absolute;
  z-index: 2;
  top: 50%;
  font-size: 55px;
  transform: translateY(-50%);
  text-align: center;
  font-weight: var(--fontWeightThick);
  line-height: 1.05;
}

.bodyText {
  display: none;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.backButton,
.forwardButton {
  position: absolute;
  top: 50%;
  display: none;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.dots {
  position: absolute;
  right: 50%;
  top: 89%;
  transform: translateX(50%);
}

.dots button:disabled {
  background-color: red;
}

.dots button {
  background-color: var(--colorBlack);
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 50%;
  margin-right: var(--spacing8);
  padding: 6px;
}

@media (--mobile) {

  .imageContainer {
    min-width: 230px;
    margin-left: 0;
  }

  .contentContainer {
    margin-top: -60px;
      margin-bottom: 30px;
  }

  .dots {
    top: 95%;
  }
}
@media (max-width: 300px) {
  .imageContainer {
    min-width: 100px;
  }
}

@media (--desktop) {
  .overlayTextContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .overlayText {
    position: absolute;
    z-index: 1;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: var(--fontSizeOverlayM);
    text-align: center;
  
  }
  .styleValuesContainer {
    width: 100%;
  }
  .bodyText {
    display: block;
    max-width: 20vw;
    font-size: var(--fontSizeXS);
    position: absolute;
    right: 40px;
    top: 8px;
  }

  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .contentContainer {
    justify-content: flex-start;
    margin-top: -70px;
  }

  .carouselProvider {
    width: 100%;
  }

  .imageContainer {
    align-self: center;
    margin: 0px 150px 0px 150px;
  }

  .slide {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 1440px;
    left: 50%;
    transform: translateX(-50%);
    max-height: 800px;
  }

  .forwardButton {
    position: absolute;
    right: 0%;
    display: block;
    margin-right: 40px;
    transform: translateY(-50%);
  }

  .backButton {
    position: absolute;
    left: 0%;
    display: block;
    margin-left: 40px;
    transform: translateY(-50%) scale(-1);
  }

  .dots {
    display: none;
  }

  .imageContainer {
    width: 32vw;
  }
}

@media (--desktopXL) {
  .overlayText {
    font-size: var(--fontSizeOverlayL);
  }
  .forwardButton {
    margin-right: calc((100vw - 1320px) / 2);
  }

  .backButton {
    margin-left: calc((100vw - 1320px) / 2);
  }
}


@media (--desktopXXL) {
  .imageContainer {
    max-width: initial;
  }

  .slide {
    max-height: initial;
    max-width: initial;
  }

  .contentContainer {
    max-height: initial;
  }

  .backButton {
    left: var(--spacing72);
    margin-left: 0;
  }
  .forwardButton {
    right: var(--spacing72);
    margin-right: 0;
  }

  .bodyText {
    font-size: var(--fontSizeL);
    max-width: 500px;
  }

}