.subscriptionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: var(--spacing24);
}

.columnContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.title {
  font-size: var(--fontSizeS);
  margin-bottom: 4vw;
  font-weight: var(--fontWeightThick);
}

.titleSwitchSubscription {
  font-weight: var(--fontWeightThick);
  font-size: var(--fontSizeL);
  margin-bottom: var(--spacing32);
  align-self: center;
}

.subTitle {
  font-weight: var(--fontWeightThick);
  padding-bottom: var(--spacing16);
}

.contentContainer {
  margin-bottom: var(--spacing16);
}

.button {
  align-self: center;
  margin-top: var(--spacing40);
}

.bodyText {
  font-size: var(--fontSizeXS);
  margin-bottom: var(--spacing32);
  margin-top: -14px;
}

.boldText {
  font-weight: var(--fontWeightThick);
}

@media (--desktop) {
  .subscriptionContainer {
    width: 50%;
    margin-bottom: 2vw;
  }

  .bodyText {
    max-width: 25vw;
  }

  .button {
    align-self: flex-start;
    margin-top: auto;
  }
}

@media (--desktopXL) {
}
