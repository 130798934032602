.carouselContainer {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.imageCredit {
  padding-top: var(--spacing8);
  margin: 4px 20px 50px 20px;
  font-size: var(--fontSizeXSStatic);
  font-weight: var(--fontWeightThick);
}

.imageContainer > a:hover {
  color: var(--colorOrange);
}

@keyframes move {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.image {
  margin: 60px 20px 0px 20px;
  max-height: 65vw;
  height: 65vw;
  object-fit: contain;
  cursor: pointer;
}

/** for hover animation adjustment */
.carouselContainer {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.carouselContainer > .contentContainer {
  height: 100%;
  margin: 0;
  transition: all 0.3s ease;
  display: flex;
  animation: move 50s linear infinite;
}

.carouselContainer:hover > .contentContainer {
  animation-play-state: paused;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (--mobile) {
}

@media (--desktop) {
  .image {
    margin: 7vh 10vw 0px 10vw;
    max-height: 70vh;
  }

  .imageCredit {
    /* margin: 4px 100px 50px 100px; */
        margin: 4px 10vw 11vh 10vw;
  }
}

@media (--desktopXL) {

}
