.sliceContainer {
  margin-top: var(--spacing40);
  gap: var(--spacing40);
  display: flex;
  flex-direction: column;
}

.sliceContainerCentered {
  margin-top: var(--spacing40);
  gap: var(--spacing40);
  display: flex;
  flex-direction: column;
}

.successMessage {
  font-size: var(--fontSizeL);
  margin-top: var(--spacing48);
  font-weight: var(--fontWeightBold);
  text-align: center;
}

.containerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image1 {
  width: 100%;
  height: auto;
}

.bodyText {
  font-size: var(--fontSizeL);
}

.textCredit {
  font-size: var(--fontSizeXS);
  margin-bottom: var(--spacing40);
}

.bodyText {
  font-weight: var(--fontWeightThick);
}

.image2,
.image2Right {
  width: 100%;
  height: auto;
}

@media (--desktop) {
  .sliceContainer {
    flex-direction: row;
    width: 100%;
    gap: var(--spacing80);
  }

  .sliceContainerCentered {
    /* height: calc(100vw - 260px); */
    margin-top: 180px;
    align-items: center;
    flex-direction: row;
  }
  .containerColumn {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .containerColumnCenter {
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }

  .image1 {
    width: 100%;
  }

  .bodyText {
    font-size: var(--fontSizeL);
    max-width: 670px;
    width: calc(100vw / 2);
  }

  .bodyText > p:first-child {
    margin-top: 0px;
  }

  .image2,
  .image2Right {
    margin-top: 68px;
    width: 100%;
  }

  .image2Right {
    text-align: right;
  }
}
