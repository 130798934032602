.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.title {
  font-size: var(--fontSizeL);
  margin-top: 20px; /* + spacing from section is 40px */
  margin-bottom: var(--spacing40);
  font-weight: var(--fontWeightThick);
}

.title p {
  margin: 0;
}

.optionsContainer {
  display: flex;
  flex-direction: row;
  gap: var(--spacing56);
  width: 100%;
}

.button {
  margin: var(--spacing80) 0px;
}

.buttonGifting {
  align-self: center;
  padding-top: var(--spacing48);
}

.checkout {
  flex-direction: column;
  display: flex;
  gap: var(--spacing24);
  width: calc(100% - var(--spacing48));
  justify-content: space-between;
}

.checkoutRow {
  display: flex;
  flex-direction: column;
  gap: var(--spacing24);
}

.checkoutRow > form {
  justify-self: center;
}

.checkoutColumn {
  display: flex;
  flex-direction: column;
}

.custom {
  font-size: var(--fontSizeXL);
  font-weight: var(--fontWeightThick);
}

.checkout * input:focus {
  border: 1px solid var(--colorOrange);
  outline-offset: 0px !important;
  outline: none !important;
}

.messageTitleNoPadding {
  font-weight: var(--fontWeightBold);
  padding-bottom: var(--spacing16);
}

.subtitle {
  font-weight: var(--fontWeightStandard);
  font-size: var(--fontSizeXXS);
  margin-top: var(--spacing8);
  margin-bottom: 12px;
  color: var(--textGrey);
}

.errorMessage {
  padding-top: var(--spacing24);
}

@media (max-width: 800px) {
  .optionsContainer {
    overflow-x: auto;
    padding-left: var(--spacing24);
    padding-right: var(--spacing24);
    margin-bottom: var(--spacing32);
    scroll-snap-type: x mandatory;
  }
}

@media (--desktop) {
  .optionsContainer {
    display: flex;
    flex-direction: row;
    gap: 3vw;
  }

  .checkout > form {
    margin-right: 35px;
  }

  .title {
    margin-top: var(--spacing24);
    margin-bottom: var(--spacing56);
  }
  .checkout {
    margin-top: var(--spacing32);
    display: flex;
    width: 100%;
    /* flex-direction: row; */
    /* justify-content: ; */
  }
  .checkoutRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .checkoutColumn {
    width: 50%;
  }
}

@media (--desktopXL) {
  .title {
    margin-bottom: var(--spacing72);
  }

  .optionsContainer {
    /* gap: var(--spacing40) */
  }

  .container {
    margin-bottom: var(--spacing24);
  }
}

/* Slider */

.carouselProvider {
  width: 100%;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing24);
}

.dots button:disabled {
  background-color: var(--colorOrange);
}

.dots button {
  background-color: var(--colorBlack);
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 50%;
  margin-right: var(--spacing8);
  padding: 6px;
}
