.title {
  font-size: var(--fontSizeS);
  font-weight: var(--fontWeightThick);
  margin-bottom: 6vw;
}

.newInContainer {
  min-height: inherit;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contentContainer {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing16);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4vw;
  margin-bottom: 1.5vw;
}

.imageItem {
  margin-bottom: var(--spacing32);
  cursor: pointer;
  flex: 1;
}

.imageItem span,
.imageItem img {
  aspect-ratio: 3 / 4;
  object-fit: cover;
}

.imageItem:hover {
  color: var(--colorOrange);
}

.imageItem:nth-child(3) {
  display: none;
}
.imageCredit {
  font-weight: var(--fontWeightThick);
  margin-top: var(--spacing8);
  font-size: var(--fontSizeXXS);
}

@media (--mobile) {
}

@media (--desktop) {
  .contentContainer {
    gap: 6vw;
  }
  .imageItem:nth-child(3) {
    display: inline;
  }
  .imageItem {
    /* max-width: 350px; */
  }
  /* .imageItem {
    max-width: 300px;
  } */
}

@media (--desktopXL) {
  .imageItem {
    /* max-width: 450px; */
  }
}


@media (--desktopXXL) {
  .imageItem {
    max-width: none !important;
  }
}