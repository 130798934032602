.container {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  background-color: var(--colorWhite);
  padding: var(--spacing16);
  position: relative;
  flex: 1;
}

.activeContainer {
  background-color: var(--colorOrange);
}

.flexContainerColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.textContainer {
  flex: 1;
}

.image {
  max-width: 360px;
}

.image img {
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.option {
  font-size: var(--fontSizeL);
  margin: var(--spacing16) 0px;
  font-weight: var(--fontWeightThick);
}

.title,
.textBold {
  font-weight: var(--fontWeightThick);
  margin-bottom: var(--spacing8);
}

.title,
.text {
  margin-bottom: var(--spacing8);
}

.text {
  font-size: var(--fontSizeXS);
  line-height: 1.6;
}

.button {
  margin-top: var(--spacing24);
  margin-bottom: var(--spacing8);
  cursor: pointer;
  display: flex;
  justify-content: center;
}

@media (max-width: 800px) {
  .container {
    min-width: 70vw;
    scroll-snap-align: center;
  }
}


@media (--desktop) {
  .button {
    justify-content: flex-start;
    margin-top: var(--spacing16)

  }
}