.container {
  max-width: 400px;
  background-color: var(--colorWhite);
  padding: 20px;
  position: relative;
  flex: 1;
}

.flexContainerColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image {
  max-width: 360px;
}

.option {
  font-size: var(--fontSizeL);
  margin: var(--spacing16) 0px 0px 0px;
  font-weight: var(--fontWeightThick);
}

.textBold {
  font-weight: var(--fontWeightThick);
  margin-bottom: var(--spacing8);
}

.title {
  font-size: var(--fontSizeL);
}
.title,
.text {
  margin-bottom: var(--spacing24);
}

.button {
  margin-top: var(--spacing40);
  margin-bottom: var(--spacing24);
}

.dots {
  /* text-align: right; */
  position: absolute;
  right: 20px;
  transform: translateY(var(--spacing16));
}

.dots button {
  background-color: var(--colorBlack);
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-left: var(--spacing8);
}

.dots button:disabled {
  background-color: red;
}
