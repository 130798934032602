.title {
  font-size: var(--fontSizeL);
  font-weight: var(--fontWeightThick);
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding-top: 88px;
  padding-bottom: 24px;
}

.termsContainer {
  min-height: inherit;
  height: 100%;
  width: 100%;
  max-width: 860px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contentContainer {
  height: auto;
}

@media (--desktop) {
  .contentContainer {
    padding: 0px 40px;
  }
}
