.title {
  font-size: var(--fontSizeS);
  font-weight: var(--fontWeightThick);
  margin-bottom: var(--spacing48);
}

.styleValuesBreakdownContainer {
  min-height: inherit;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contentContainer {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing32);
}

.valuesItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px var(--spacing24) 0px var(--spacing16);
  padding-bottom: var(--spacing32);
  font-size: var(--fontSizeXS);
}

.itemBodyText {
  text-align: center;
}

.itemBodyText p {
  margin: 0px 0px 0px 0px;
  margin-bottom: 12px;
  line-height: 1.4;
}

.itemTitle {
  font-weight: var(--fontWeightThick);
  margin-top: var(--spacing16);
  margin-bottom: var(--spacing8);
}

.image {
  object-fit: contain;
}

@media (--mobile) {
}

@media (--desktop) {

  .title {
    margin-bottom: 6vw;
  }

  .button {
    margin-top: 5vw;
    margin-bottom: 2vw;
  }
  .contentContainer {
    flex-direction: row;
    align-items: initial;
  }
  .itemBodyText {
    text-align: left;
  }

  .valuesItem {
    align-items: flex-start;
    margin: 0px 0px 0px var(--spacing16);
    padding-right: var(--spacing24);
  }

  .itemContainer {
    border-right: 1px solid black;
    flex: 1;
  }

  .itemContainer:first-child {
    border-left: 1px solid black;
  }
  .image {
    object-position: 0 100%;
  }
}

@media (--desktopXL) {
}
