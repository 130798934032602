.container {
  border: 1px solid black;
  border-radius: 80px;
  padding: 15px;
  cursor: pointer;
}

.container:hover {
  color: var(--textGrey);
}

.container:hover > .innerContainer > .letter,
.optionActive .letter {
  background-color: var(--colorOrange);
}


.optionActive {
  color: var(--colorOrange);
  border-color: var(--colorOrange)
}


.innerContainer {
  display: flex;
  flex-direction: row;
  font-size: var(--fontSize13);
  align-items: center;
}

.letter {
  background-color: var(--colorBlack);
  border-radius: 100px;
  height: 56px;
  width: 56px;
  min-width: 56px;
  text-align: center;
  text-justify: center;
  font-size: 38px;
  padding-top: 3px;
  color: var(--textWhite);
  margin-right: 30px;
  align-self: center;
}

.letter:hover {
  background-color: var(--colorOrange);
}

.textBold {
  font-weight: var(--fontWeightThick);
}

.text {
  margin-top: 8px;
}

@media (--desktop) {
  .letter {
    margin-right: 76px;
    height: 63px;
    min-width: 63px;
    padding-top: 7px;
  }
}

@media (--desktopXL) {
  .letter {
    margin-right: 90px;
    height: 90px;
    min-width: 90px;
    font-size: 62px;
    padding-top: 5px;
  }
  .innerContainer {
    font-size: var(--fontSize14);
  }
}
