.title {
  font-size: var(--fontSizeM);
  font-weight: var(--fontWeightBold);
  margin-bottom: var(--spacing32);
  position: relative;
  display: flex;
  align-items: center;
}

.title > svg {
  /* position: absolute; */
  /* right: 0; */
  fill: var(--colorBlack);
  stroke: var(--colorGrey);
  transform: rotate(0deg);
  transition: 400ms;
  margin-right: var(--spacing16);
}

.title > svg > path {
  fill: var(--colorGrey);
}

.dropdown {
  border-top: 1px solid var(--colorGrey);
  border-bottom: 1px solid var(--colorGrey);
  padding-top: var(--spacing32);
  cursor: pointer;
}

.dropdownOpen > .title > svg {
  transform: rotate(45deg);
}

.filterDropdownOpen {
  padding-bottom: var(--spacing16);
}

.dropdown ~ .dropdown {
  border-top: none;
}

.dropdownInfo {
  font-size: var(--fontSizeXS);
  padding-bottom: var(--spacing16);
  padding: 0px 35px;
  white-space: pre-line;
  padding-bottom: var(--spacing16);
}
