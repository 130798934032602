.title {
  font-size: var(--fontSizeS);
  font-weight: var(--fontWeightThick);
}

.aboutUsContainer {
  min-height: inherit;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contentContainer {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 8vw;
  padding-bottom: 7vw;
    
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 6vw;
}

.bodyText {
  text-align: center;
  max-width: 290px;
  min-width: 250px;
  font-size: var(--fontSizeM);
  font-weight: var(--fontWeightThick);
  /* margin-bottom: 5vw; */
}


@media (--mobile) {
}

@media (--desktop) {
  .bodyText {
    max-width: 680px;
    font-size: var(--fontSizeL);
  }
}

@media (--desktopXL) {
  .bodyText {
    /* max-width: 478px; */
    font-size: var(--fontSizeXL);
  }
}
